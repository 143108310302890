import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import ReactAudioPlayer from "react-audio-player";
import Layout from "../components/Layout";
import { useQuerySubscription } from "react-datocms";

function Mogelijkheden() {
  let [allSongs, setAllSongs] = useState([]);
  let [currentSong, setCurrentSong] = useState({});
  let [loaded, setLoaded] = useState(false);
  let [switching, setSwitch] = useState(false);

  const { status, error, data } = useQuerySubscription({
    enabled: true,
    query: query,
    variables: { first: 20 },
    token: '0907f4cfec4809b1b584728b404e77',
  });
  const statusMessage = {
    connecting: 'Connecting to DatoCMS...',
    connected: 'Connected to DatoCMS, receiving live updates!',
    closed: 'Connection closed',
  };

  useEffect(() => {
    setCurrentSong(allSongs[0]);
    setLoaded(true);
  }, [allSongs]);

  useEffect(() => {
    if(data){
      setAllSongs(data.allMusics);
    }
  }, [data])

  function onSongClick(event) {
    event.preventDefault();
    const input = event.target.getAttribute("data-key");
    let result = allSongs.filter((obj) => {
      return obj.title === input;
    });
    setCurrentSong(result[0]);
  }

  return (
    <div className="mogelijkheden-page">
      <Layout>
        <section className="music-player">
          <h1>Mogelijkheden</h1>
          {/* Needs styling */}
          <p>
          Bent u enthousiast geworden van Duo Juna? Dan bespreken we graag de mogelijkheden met u! Op elke denkbare gelegenheid komen wij graag spelen: een buurtfeest, huwelijk, verjaardag, jubileum, familie bijeenkomst, borrel, diner, receptie, high-tea, uitreiking, afscheid, begrafenis, huiskamerconcert, in de concertzaal, etc. Hieronder staat een repertoire lijst met stukken die wij kunnen aanbieden. U kunt een klein fragmentje luisteren om een idee te hebben ervan. U kunt ook zelf met verzoeken komen. We overleggen met u over hoelang u wilt dat we spelen, hoe vaak, op welke locatie, in wat voor een vorm u het wilt, wat voor een muziek u wilt en andere wensen van uw kant. Wij maken ons optreden voor u op maat!
          </p>
          <div className="player-container">
            {loaded || switching ? (
              <>
                <ReactAudioPlayer
                  className="player"
                  src={currentSong && currentSong.file.url}
                  autoPlay
                  controls
                  volume
                  preload
                  controlsList="nodownload"
                />
                <h2>Huidig nummer: { currentSong && currentSong.title}</h2>
              </>
            ) : null}
          </div>

          <div className="song-list-container">
            <h2>Repetoire</h2>
            <ul className="song-list">
              {allSongs && allSongs.map((song) => {
                return (
                  <li
                    value={song.file.url}
                    onClick={onSongClick}
                    data-key={song.title}
                  >
                    {song.title}
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </Layout>
    </div>
  );
}
// query moet op alfabetische volgorde. 
export const query = `query MyQuery {
      allMusics(orderBy: title_ASC) {
        title
        file {
          url
        }
      }
    }    

`;


export default Mogelijkheden;
